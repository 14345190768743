import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import ActivateUser from "./pages/activation/ActivateUser";

import Page404 from "./pages/Page404";
import NewPasswordPage from "./pages/NewPasswordPage";
import HomePage from "./pages/HomePage";
import SuccessReset from "./pages/forms/success/SuccessReset";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import DeleteContactPage from './pages/DeleteContactPage';
import QRPage from './pages/QRPage';

const App = () => {
  return (
    <div>
      <Router>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/terms-of-use" element={<TermsPage />} />
            <Route
              path="/password/reset/confirm/:uid/:token"
              element={<NewPasswordPage />}
            />
            <Route path="/successreset" element={<SuccessReset />} />
            <Route path="/delete" element={<DeleteContactPage />} />
            <Route path="/qr" element={<QRPage />} />
            <Route path="/activate/:uid/:token" element={<ActivateUser />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
};

export default App;
