import React from 'react';

import Container from '@mui/material/Container';

import Spacer from '../infrastructure/components/Spacer';
import NewPasswordForm from './forms/NewPasswordForm';



const NewPasswordPage = () => {

    return (
            <Container component='main' maxWidth='md'>
            <Spacer height={'5rem'} />
                <NewPasswordForm />
            </Container>
    );
}

export default NewPasswordPage;
