import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Spacer from "../infrastructure/components/Spacer";

import { logo } from "../library/resources/images";

const TermsPage = () => {
  return (
    <Container component="main" maxWidth="md">
      <Spacer height={"2rem"} />
      <Link href="/" sx={{ display: "flex", justifyContent: "center" }}>
        <Box component="img" src={logo.src}></Box>
      </Link>
      <Spacer height={"4rem"} />

      <Typography
        variant="h3"
        sx={{ textAlign: "center", wordWrap: "break-word" }}
      >
        {`Opšti uslovi i pravila korišćenja aplikacije BRINITI`}
      </Typography>
      <Spacer height={"4rem"} />
      <Stack spacing={3}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Opšti uslovi i pravila korišćenja`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Opšti uslovi i pravila korišćenja aplikacije BRINITI (u daljem tekstu: Opšti uslovi) definišu uslove pristupa i upotrebe mobilne aplikacije BRINITI (u daljem tekstu: Aplikacija). Korišćenjem aplikacije potvrđujete da se slažete sa ovde navedenim uslovima. Aplikacija je razvijena i admistrira se od strane Društva sa ograničenom odgovornošću Briniti d.o.o. Beograd (u daljem tekstu: Briniti). Briniti zadržava pravo da u svako doba prekine ili delimično ili potpuno promeni Aplikaciju ili Opšte uslove.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Prenos upotrebe`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Svi detalji, dokumenta i ilustracije koje su objavljene unutar Aplikacije su vlasništvo Briniti-ja. Svaka dozvola upotrebe istoga se odobrava na osnovu relevantne beleške o autorskim pravima koja se nalazi na svim kopijama, da se takvi detalji koriste u lične svrhe, da nisu namenjeni komercijalnoj upotrebi , da nisu izmenjeni na bilo koji način i da se ilustracije koje su dobijene koriste iskljucivo sa priloženim tekstom.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Robne marke i autorska prava`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Aplikacija i eventualno korišćenje robne marke su vlasništvo Briniti-ja, osim ako nije naznačeno drugačije ili se na bilo koji način shvata kao pravo trećeg lica. Bilo kakvo neautorizovano korišćenje ovih robnih marki ili drugih materijala je izričito zabranjeno i predstavlja kršenje zakona o autorskim pravima i/ili drugih propisa zaštite industrijske svojine.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Ograničena odgovornost`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Informacije i podaci koji se nalaze u Aplikaciji, Briniti je pribavila koristeći interne i eksterne izvore po svom najboljem saznanju i uverenju i uz primenu dužne pažnje. Briniti neprestano nastoji da proširi i ažurira te informacije. Međutim, nisu dati ni uverenje niti garancija, bilo izričito ili prećutno, koji bi se odnosili na kompletnost ili tačnost informacija prikazanih u Aplikaciji. Preporuke koje su date u Aplikaciji ne sprečavaju Vas da obavite vlastite provere podataka. Briniti i treće strane koje učestvuju u kreiranju Aplikacije ne mogu biti odgovorni za štetu ili povredu koje nastanu kao rezultat pristupa ili nemogućnosti pristupa podacima koje nudi Aplikacija, te korišćenja ili nemogućnosti korišćenja Aplikacije ili kao rezultat činjenice da ste se oslonili na informacije objavljene u Aplikaciji.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Veb sajtovi/ linkovi trećih strana`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Aplikacija sadrži linkove/reference za veb sajtove trećih strana. Obezbeđujući takve linkove, Briniti ne daje svoju saglasnost u pogledu sadržaja tih veb sajtova. Osim toga, Briniti ne prihvata nikakvu odgovornost za dostupnost ili sadržaj tih veb sajtova, niti odgovornost za štetu ili povredu koje nastanu kao rezultat korišćenja njihovog sadržaja, u bilo kom obliku. Linkovi za druge veb sajtove daju se korisnicima programa isključivo radi praktičnosti. Korisnici pristupaju takvim veb sajtovima na vlastitu odgovornost. Izbor linkova ne može ni na koji način da ograniči korisnike na korišćenje stranica čije su linkove upotrebili.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Međunarodni korisnici`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Ovaj program se proverava, administrira i ažurira od strane Briniti-ja u Beogradu, u Republici Srbiji. Namenjen je isključivo upotrebi na teritoriji Republike Srbije. Briniti ne garantuje da su podaci koji su predstavljeni programom tačni van teritorije Republike Srbije i naročito da će proizvodi i usluge biti dostupni u istom obliku, veličini i pod istim uslovima u čitavom svetu. Ukoliko pokrenete program van teritorije Republike Srbije ili skinete nekakav sadržaj, obratite pažnju da je Vaša sopstvena odgovornost da radite u skladu sa lokalnim zakonima koji se primenjuju na datom mestu.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Primenjeno pravo`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Bilo kakvo pravno tumačenje ili eventualni postupak koji proizilazi u vezi korišćenja Aplikacije su predmet interpretacije prava Republike Srbije.`}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Privatnost`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          Briniti je svesna da je bezbednost Vaših ličnih podataka u vezi sa upotrebom Aplikacije veoma važna. Iz razloga što zaštitu Vaših podataka shvatamo veoma ozbiljno, način prikupljanja i uslovi obrade podataka o ličnosti definisane su posebnim dokumentom – Politika privatnosti koji je dostupan na <Link href='/privacy-policy'>www.briniti.com/privacy-policy</Link>.
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {`Kontakt`}
        </Typography>
        <Typography sx={{ textAlign: "justify" }}>
          {`Ukoliko imate bilo kakav problem, pitanje ili ideju kontaktirajte:`}
        </Typography>
      </Stack>
      <Typography sx={{ textAlign: "justify" }}>
        {`Briniti d.o.o. Beograd, Republika Srbija`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Telefon: +381 65 4725915`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        Elektronska pošta: <Link href='mailto:office@briniti.com'>office@briniti.com</Link>
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Preduzeće je registrovano u Agenciji za privredne registre u Beogradu`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Matični broj: 21785113`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>{`PIB: 112997153`}</Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Šifra delatnosti 6201 – Računarsko programiranje`}
      </Typography>
      <Spacer height={"2rem"} />
    </Container>
  );
};

export default TermsPage;
