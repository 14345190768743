import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import { authService } from "../../library/services/authService";
import Spacer from "../../infrastructure/components/Spacer";
import { logo } from "../../library/resources/images";

import "../css/style.css";

const initialValues = {
  new_password: "",
  re_new_password: "",
};

const NewPasswordForm = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const resetPassword = async (values: any) => {
    try {
      const new_password = values.new_password;
      const re_new_password = values.re_new_password;

      if (new_password === re_new_password) {
        const res = (await authService.resetPasswordConfirm(
          uid,
          token,
          new_password
        )) as any;

        if (res.status === 204) {
          navigate(`/successreset`);
        }
      }
    } catch (err: any) {
      if (err.response) {
        const values: any = Object.values(err.response.data);
        const keys = Object.keys(err.response.data);

        if (
          values[0][0] ===
            "This password is too short. It must contain at least 8 characters." &&
          keys[0] === "new_password"
        ) {
          setError("Lozinka je prekratka");
          return;
        } else if (
          values[0][0] === "This field may not be blank." &&
          keys[0] === "new_password"
        ) {
          setError("Polja ne smeju biti prazna");
          return;
        } else if (
          values[0][0] === "This password is too common." &&
          keys[0] === "new_password"
        ) {
          setError("Lozinka ne sme biti uobičajena");
          return;
        } else if (
          values[0][0] === "The password is too similar to the username." &&
          keys[0] === "new_password"
        ) {
          setError("Lozinka ne sme biti slična imejlu");
          return;
        }
        alert(JSON.stringify(err.response.data));
      }
    }
  };

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .required("Obavezno polje")
      .min(8, "Lozinka mora da sadrži najmanje 8 karaktera")
      .max(40, "Lozinka ne sme preći više od 40 karaktera"),
    re_new_password: yup
      .string()
      .required("Obavezno polje")
      .oneOf([yup.ref("new_password"), null], "Lozinke se ne podudaraju"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: resetPassword,
  });

  return (
    <Container component="main" maxWidth="sm">
      <div>
        <Link href="/" sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="img" src={logo.src}></Box>
        </Link>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h4"
                sx={{
                  textAlign: "center",
                  padding: "2rem",
                }}
              >
                <span className="color-1">Zaboravlj</span>
                <span className="color-2">ena lozi</span>
                <span className="color-3">nka</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="new_password"
                label="Nova lozinka"
                name="new_password"
                variant="outlined"
                type="password"
                value={formik.values.new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="re_new_password"
                label="Ponovi lozinku"
                name="re_new_password"
                variant="outlined"
                type="password"
                value={formik.values.re_new_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.re_new_password &&
                  Boolean(formik.errors.re_new_password)
                }
                helperText={
                  formik.touched.re_new_password &&
                  formik.errors.re_new_password
                }
              />
            </Grid>
            <Grid item xs={12}>
              {error !== "" && <p style={{ color: "red" }}>{error}</p>}
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={{ backgroundColor: "#ff7272" }}
              >
                Potvrdi
              </Button>
            </Grid>
          </Grid>
        </form>
        <Spacer height={"15rem"} />
      </div>
    </Container>
  );
};

export default NewPasswordForm;
