import React from 'react'
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Spacer from '../../infrastructure/components/Spacer';
import { authService } from '../../library/services/authService';


const ActivateUser = () => {

  let { uid, token } = useParams()

  React.useEffect(() => {
    const callAuthService = async () => {
      try {
        await authService.activateAccount(uid, token)
      }
      catch (error) {

      }
    }
    callAuthService()
  }, [])


  return (
    <Container component='main' maxWidth='md'>
      <Spacer height={'15rem'} />
      <Typography component='h1' variant='h2' className='text-center'>
        Dobrodošli na Briniti
      </Typography>
      <Typography component='h1' variant='h4' className='text-center pt-3'>
        Uspešno ste aktivirali nalog
      </Typography>
      <Typography component='h1' variant='h6' className='text-center pt-3'>
        Nastavite sa korišćenjem mobilne aplikacije
      </Typography>
    </Container>
  )
}

export default ActivateUser
