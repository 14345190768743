export const logo = {
    src: require('../../infrastructure/img/logo.png')
}

export const apps = {
    src: require('../../infrastructure/img/apps.jpg')
}

export const onama = {
    src: require('../../infrastructure/img/onama.png')
}

export const pozadina = {
    src: require('../../infrastructure/img/phone1.png')
}

export const playstore = {
    src: require('../../infrastructure/img/playstore.svg').default
}

export const appstore = {
    src: require('../../infrastructure/img/appstore.svg').default
}
