import {
  apps,
  pozadina,
  onama,
  appstore,
  playstore,
} from "../library/resources/images";

import "./styles/HomePage.css";
import "./css/bootstrap.min.css";
import "./css/style.css";
import ReactPlayer from "react-player";

const HomePage = () => {
  return (
    <div>
      <header id="header">
        <div className="container h-100 d-flex align-items-center">
          <a
            href="/"
            style={{ textDecoration: "none" }}
            className="logo me-auto"
          >
            <h2>
              <span className="color-1">BRIN</span>
              <span className="color-2">I</span>
              <span className="color-3">TI</span>
            </h2>
          </a>
          <nav id="navbar" className="navbar">
            <ul className="d-lg-flex">
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Početna
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  O nama
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#application">
                  O aplikaciji
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section
        id="hero"
        className="hero-area bg-img bg-overlay"
        style={{ backgroundImage: `url(${pozadina.src})` }}
      ></section>

      <section className="section-before bg-color-1"></section>

      <section id="about" className="about-area p-0 bg-section">
        <div className="container d-flex flex-wrap">
          <div className="about-content">
            <div className="section-heading">
              <p>O nama</p>
              <h2>
                <span>Ko</span> smo
              </h2>
            </div>
            <p>
              Okupljeni smo oko ideje da ljudima koji brinu o svom zdravlju i
              zdravlju svoje dece ponudimo alat u formi aplikacije koja će im
              omogućiti da zabeleže, sačuvaju, klasifikuju, kvantifikuju i
              prezentuju zdravstvene podatke.
            </p>
          </div>
          <div
            className="about-thumbnail bg-img parallax"
            style={{ backgroundImage: `url(${onama.src})` }}
          ></div>
        </div>
      </section>

      <section className="section-before bg-color-2"></section>

      <section id="application" className="bg-color-2">
        <div className="bg-img"></div>
        <div className="app-content bg-white">
          <div className="container">
            <div
              className="section-heading"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ paddingBottom: "2rem" }}>
                <span className="color-2">O aplikaciji</span>
              </p>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <ReactPlayer
                  width={"80%"}
                  url={`https://www.youtube.com/watch?v=8oRHYIEgcbw`}
                />
              </div>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <a
                  target="_blank"
                  href="https://apps.apple.com/app/briniti/id1661280193"
                  rel="noreferrer"
                  style={{marginRight: '0.5rem'}}
                >
                  <img src={appstore.src} alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.briniti"
                  rel="noreferrer"
                  style={{marginLeft: '0.5rem'}}
                >
                  <img src={playstore.src} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-before bg-color-3"></section>

      <footer
        className="footer-area bg-img bg-overlay parallax"
        style={{ backgroundImage: `url(${apps.src})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="footer-info mb-lg-4">
                <p>
                  <i className="bi bi-map"></i>
                  <span>Beograd</span>
                </p>
                <p>
                  <i className="bi bi-envelope"></i>
                  <span>
                    <a className="color-1" href="mailto:office@briniti.com">
                      office@briniti.com
                    </a>
                  </span>
                </p>
                <p className="mb-0">
                  <i className="bi bi-phone"></i>
                  <span>
                    <a className="color-1" href="tel:+381654725915">
                      +381 65 4725915
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="copywrite-area">
          <div className="container h-100 d-flex align-items-center">
            <div className="policy-box d-none d-md-block">
              <span>
                &copy;<script>2022</script>
              </span>
              <span className="color-1">BRIN</span>
              <span className="color-2">I</span>
              <span className="color-3">TI</span>
              <span> All rights reserved</span>
            </div>
            <div className="color-1 ms-auto">
                <a className="links-policy" href="/privacy-policy">Politika privatnosti</a>|
                <a className="links-policy" href="/terms-of-use">Uslovi korišćenja</a>
              </div>

          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
