import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Spacer from "../infrastructure/components/Spacer";

import { logo } from "../library/resources/images";

const PrivacyPage = () => {
  return (
    <Container component="main" maxWidth="md">
      <Spacer height={"2rem"} />
      <Link href="/" sx={{ display: "flex", justifyContent: "center" }}>
        <Box component="img" src={logo.src}></Box>
      </Link>
      <Spacer height={"4rem"} />

      <Typography
        variant="h3"
        sx={{ textAlign: "center", wordWrap: "break-word" }}
      >
        {`POLITIKA PRIVATNOSTI BRINITI d.o.o. BEOGRAD`}
      </Typography>
      <Spacer height={"4rem"} />
      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`Uvod`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Korišćenjem mobilne aplikacije „Briniti“ (u daljem tekstu: Aplikacija) se u određenim slučajevima i pod određenim uslovima, prikupljaju i dalje obrađuju podaci o ličnosti.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Ova Politika privatnosti ima za cilj da obrada Vaših podataka bude transparentna i razumljiva, kao i da objasni kako i zašto Briniti d.o.o. Beograd (u daljem tekstu “Društvo”) u svojstvu rukovaoca obrađuje vaše podatke o ličnosti i mere koje preduzima da Vaši podaci budu sigurni i zaštićeni. Obrada podataka o ličnosti vrši se u skladu sa Zakonom o zaštiti podataka o ličnosti.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Prihvatanjem ove Politike privatnosti saglašavate se sa korišćenjem Vaših podataka o ličnosti prema uslovima navedenim u ovoj Politici.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`1. Rukovalac`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        Rukovalac Podacima o ličnosti je Briniti d.o.o. Beograd, matični broj:
        21785113, PIB: 112997153, broj telefona: +381 65 4725915, e-mail adresa:
        ofice@briniti.com. Društvo kao Rukovalac određuje svrhu i način obrade
        Podataka o ličnosti.
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`U cilju maksimalne zaštite podataka o ličnosti, Rukovalac u svemu postupa u skladu sa odredbama važećeg Zakona o zaštiti podataka o ličnosti i posluje u skladu sa principima poslovanja koji poštuju najviše standarde poštovanja privatnosti. S tim u vezi, Društvo, kao rukovalac Podacima o ličnosti, Korisnike upoznaje sa osnovnim informacijama u vezi sa prikupljanjem i daljom obradom njihovih Podataka o ličnosti prilikom korišćenja Aplikacije.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`2. Pravni osnov obrade`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Lični podaci obrađuju se isključivo kada za to postoji zakonska osnova: pravna obaveza, ugovorni odnos, pristanak korisnika, zaštita životno važnih interesa Korisnika, legitimni interes Društva i izvršenje zakonom propisanih ovlašćenja.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Pravni osnov obrade prikupljenih Podataka o ličnosti je saglasnost Korisnika za obradu Podataka o ličnosti. Datu saglasnost za obradu, Korisnik u svakom trenutku može opozvati, s tim da opoziv saglasnosti ne utiče na dopuštenost obrade Podataka o ličnosti koja je vršena do trenutka opoziva.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Legitimni interes Društva predstavlja: sprečavanje prevara, direktan marketing, zaštita lica, poslovanja i imovine Društva, unapređenje proizvoda i usluga.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`3. Koje podatke o ličnosti prikupljamo`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        <b>Lični podaci:</b> ime i prezime, datum rođenja.
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        <b>Kontakt podaci:</b> e-mail adresa.
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        <b>Podaci o korišćenju:</b> e-mail adresa i kriptovana lozinka.
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        <b>Javno objavljeni podaci:</b> podaci koji su na bilo koji način javno
        objavljeni, npr. na internet stranicama ili društvenim mrežama.
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        <b>Osetljivi podaci::</b> podaci o zdravlju.
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`4. Kada prikupljamo Vaše podatke`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Vaše podatke prikupljamo prilikom: Registracije na aplikaciju i mobilnu aplikaciju. Prihvatanjem Pravila o uslovima korišćenja Briniti aplikacije, postajete Korisnik i stupate u ugovorni odnos sa Društvom. Kako biste koristili uslugu Briniti aplikacije, potrebno je otvoriti korisnički nalog. Prilikom kreiranja korisničkog naloga na Aplikaciji i prilikom daljeg korišćenja Aplikacije, prikupljaju se i obrađuju sledeći Podaci o ličnosti Korisnika:`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Ime i prezime;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Lozinka;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`E-mail adresa;`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Nakon otvaranja korisničnog naloga, prilikom kreiranja profila tražimo`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Datum rođenja;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`E-mail adresa (za kontakt)`}
      </Typography>

      <Typography sx={{ textAlign: "justify" }}>
        {`Rukovalac, navedene Podatke o ličnosti prikuplja i dalje obrađuje sa ciljem omogućavanja Korisnicima da kreiraju korisnički nalog u okviru Aplikacije, omogućavanja Korisnicima korišćenja sadržaja koje Aplikacija nudi, radi izrade statističkih podataka vezanih za korišćenje Aplikacije, unapređivanja usluga koje Aplikacija pruža i omogućavanja jednostavnijeg i efikasnijeg korišćenja Aplikacije, prilagođavanja Aplikacije potrebama i interesovanjima Korisnika, unapređenja korišćenja Aplikacije od strane Korisnika i pružanja Korisnicima više pogodnosti u korišćenju Aplikacije. Prikupljanje i dalju obradu Podataka o ličnosti Korisnika koji koristi Aplikaciju vrši Rukovalac, i to samo na osnovu saglasnosti Korisnika, koju Korisnik daje registracijom na aplikaciju. Rukovalac obradu Podataka o ličnosti vrši samo u prethodno navedene svrhe, a u slučaju da se naknadno utvrdi još neka svrha obrade, Korisnici će o istoj biti uredno obavešteni pre početka obrade.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`5. Komunikacije sa Korisnikom aplikacije`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Društvo Korisnicima pruža korisničku podršku putem email-a.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`6. Oglašavanje na sajtovima trećih strana`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Mi koristimo treće strane koje prikupljaju podatke o posetiocima njihovih aplikacija i sajtova kroz spostvene kolaćiče, web beacons i druge tehnologije u vezi sa Vašim online aktivnostima, u cilju razumevanja Vašeg interesovanja i oglašavanja u skladu sa Vašim interesovanjima. Obezbeđujemo prostor na sajtovima trećih strana i naše reklame se mogu pojaviti kada treća strana proceni da naša reklama odgovara Vašim interesovanjima prema informacijama koje su prikupili.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Vaše podatke o ličnosti ne dostavljamo oglašivačima, niti sajtovima trećih strana koje objavljuju naše reklame bazirane na Vašim interesovanjma, međutim, postoji mogućnost da prikupe neke Vaše podatke o ličnosti kroz korišćenje njihovog sajta (pročitan članak, tip reklame na koju ste kliknuli itd.)  i oni mogu da prilagode reklamni materijal objavljen na njihovim sajtovima shodno Vašim interesovanjima.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Mi nemamo pristup niti kontrolu nad kolačićima koje oglašivači ili sajtovi trećih strana mogu koristiti, a naša Politika privatnosti ne pokriva njihovo rukovanje podacima. Za više informacija o njihovim politikama privatnosti, molimo Vas kontaktirajte ih direktno.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`7. Primaoci podataka o ličnosti`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Društvo lične podatke Korisnika prosleđuje drugim primaocima isključivo na osnovu zakonske obaveze i poštovanja regulatornih zahteva, odnosno opravdanog zahteva ovlašćenog javnog tela, državnih organa ili u slučaju postavljanja, ostvarenja ili odbrane pravnih zahteva. Po potrebi, lični podaci se prosleđuju pouzdanim poslovnim partnerima (obrađivačima) radi omogućavanja održavanja informacionog sistema, rešavanja korisničkih zahteva ili sličnih poslovnih potreba uz obavezne mere zaštite koje se uređuju i definišu posebnim ugovorima.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Vaši podaci o ličnosti mogu biti otkriveni: sudovima, regulatornim telima na osnovu pisanog zahteva državnog organa u skladu sa Zakonom.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`8. Gde čuvamo Vaše podatke o ličnosti`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Rukovalac prikupljene podatke o ličnosti čuva samo tokom vremena u kom je aktivan korisnički nalog Korisnika, odnosno dok Korisnik koristi Aplikaciju. U slučaju da Korisnik deaktivira korisnički nalog ili deaktivira ovu Aplikaciju, Rukovalac će obrisati Podatke o ličnosti Korisnika. `}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Vaši podaci o ličnosti čuvaju se na serverima koji se nalaze na teritoriji Evrope. Ukoliko podatke koje sakupimo od Vas prenesemo nekom primaocu ti primaoci podataka će skladištiti Vaše podatke. Trudimo se da osiguramo da se sa Vašim podacima o ličnosti postupa u skladu sa ovom Politikom i da primaoci podataka poseduju iste ili približne organizacione, tehničke i kadrovske mere kao mi. Neki od primalaca podataka sa kojima delimo Vaše podatke o ličnosti mogu imati servere koji se nalaze van teritorija koje prema propisima Republike Srbije obezbeđuju primereni nivo zaštite, u kom slučaju ćemo obezbediti odgovarajuće mere zaštite.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`9. Rok čuvanja`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Vaše podatke zadržavamo samo onoliko koliko je potrebno da bismo ostvarili napred navedene svrhe, odnosno ispunili naše zakonske obaveze. Vaše podatke o ličnosti čuvamo u periodu u kom ste Korisnik naših usluga. Kada prestanete da koristite naše usluge, podaci o ličnosti se čuvaju određeni vremenski period u skladu sa Zakonom o zaštiti podataka o ličnosti.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`10. Prava Korisnika`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`U zavisnosti od vrste i pravnog osnova obrade, možete ostvariti sledeća prava u vezi sa obradom podataka o ličnosti:`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Pristup podacima o ličnosti;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Ispravku podataka o ličnosti;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Brisanje podataka o ličnosti;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Prenosivost podataka o ličnosti;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Ograničenje obrade podataka o ličnosti;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Pravo na prigovor;`}
      </Typography>
      <Typography
        sx={{ textAlign: "justify", display: "list-item", marginLeft: 5 }}
      >
        {`Pravo u vezi sa automatizovanim donošenje pojedinačnih odluka i profilisanje;`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`U okviru korisničkog naloga samostalno možete ostvariti neka od navedenih prava-pristupiti svojim podacima, ispraviti netačne ili promenjene podatke. Za sva ostala pitanja i zahteve molimo Vas da se obratite Službi korisničke podrške. Prikupljanje i dalja obrada Podataka o ličnosti se vrši u gore opisane svrhe i navedene svrhe ne bi bilo moguće ostvariti ukoliko Korisnici u okviru predviđenih polja u Aplikaciji ne ostave svoje Podatke o ličnosti Rukovaocu (između ostalog, Korisnici ne mogu da učestvuju u nagradnom konkursu ili da prime nagradu osvojenu na nagradnom konkursu, itd.). Rukovalac će u smislu odredbi Zakona o zaštiti podataka o ličnosti na odgovarajući način zaštititi Podatke o ličnosti Korisnika koji se obrađuju, od zloupotreba, uništenja, gubitka, neovlašćenih promena ili pristupa, odnosno preduzeće sve neophodne tehničke, kadrovske i organizacione mere zaštite podataka, u skladu sa utvrđenim standardima i postupcima, a koje su potrebne da bi se podaci zaštitili od gubitka, uništenja, nedopuštenog pristupa, promene, objavljivanja i svake druge zloupotrebe.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`11. Sigurnost podataka o ličnosti`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Društvo, štiti podatke o ličnosti primerenim organizaciono-tehničkim i pravnim merama zaštite. Prilikom određivanja ovih mera zaštite uvek uzimamo u obzir količinu, vrstu i osetljivost podataka, kao i druge okolnosti koje utiču na procenu eventualnih rizika za zaštitu Vaših prava u sloboda. Isti nivo odgovornosti i sigurnosti zahtevamo i od naših ugovornih partnera (obrađivača).`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Pristup podacima o ličnosti Korisnika imaju samo zaposleni kod Rukovaoca koji upravljaju ovom Aplikacijom i održavaju je, te omogućavaju Korisnicima korišćenje različitih sadržaja Aplikacije.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Svi Vaši podaci su kriptovani SSL tehnologijom.`}
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: "2rem" }}>
        {`12. Žalbe`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Za sva pitanja, zahteve, pritužbe i prigovore koji se odnose na zaštitu i obradu podataka o ličnosti možete se obratiti našem korisničkom servisu na jedan od sledećih načina:`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        Email: office@briniti.com
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        Takođe, imenovali smo Službenika za zaštitu podataka o ličnosti kome se
        možete obratiti na e-mail office@briniti.com. Lice za zaštitu podataka o
        ličnosti zaduženo je za nadzor zakonitosti obrade podataka o ličnosti,
        brigu o zaštiti prava i sloboda korisnika (i ostalih lica čije podatke
        obrađuje) i savetovanje odgovornih lica o najboljim praksama u oblasti
        zaštite podataka o ličnosti.
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Svaki primljeni zahtev biće rešen što je moguće pre, a najkasnije u roku od 30 dana od prijema. Prilikom kontakta i postavljanja navedenih zahteva uložićemo razumne napore da utvrdimo Vaš identitet i sprečimo neovlašćenu obradu podataka o ličnosti.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Ukoliko smatrate da su Vaši podaci nezakonito obrađivani, možete podneti pritužbu Povereniku za zaštitu informacija od javnog značaja i zaštitu podataka o ličnosti.`}
      </Typography>
      <Typography sx={{ textAlign: "justify" }}>
        {`Ova verzija Politike privatnosti je poslednji put ažurirana dana 19.11.2022. godine.`}
      </Typography>

      <Spacer height={"4rem"} />
    </Container>
  );
};

export default PrivacyPage;
